import { shaderMaterial, Sparkles, Center, useTexture, useGLTF, OrbitControls, useAnimations } from '@react-three/drei'
import ReactDOM from 'react-dom/client'
import * as THREE from 'three'
import { useFrame, useLoader, extend, useThree } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { useRef, useEffect, useState } from 'react'
import { Vector3 } from 'three'



const Bar = ({ setModalVisible, setMeshName,setIsZoomed,isZoomed,setLerpFactor,lerpFactor,setControlsTarget,setControlsEnabled, controlsTarget }) => {
  const { camera } = useThree(); 
  const [targetLookAt, setTargetLookAt] = useState(new THREE.Vector3(0, 0.3, 15.5)); // Set the desired lookAt target
  const origin = new Vector3(0, 0.3, -15.5)
  const returnOrigin = new Vector3(0, -0.4, -15.5)
  const gltf = useLoader(GLTFLoader, './model/BMBExport15.glb')
  const [meshRef, setMeshRef] = useState(null); 

  let meshName;
  const handleClick = (event) => {
    meshName = event.object.name;
    if (meshName === 'HitboxBanner') {
      setModalVisible((prevState) => !prevState);
      setMeshName(meshName);
      setLerpFactor(0);
      setMeshRef(event.object);
      setControlsEnabled(false); // Disable OrbitControls

      if (isZoomed == false) {
        setIsZoomed(true);
      } else {
        setIsZoomed(false);
      }
    } 
  };

  useFrame((state, delta) => {
    if (meshRef) {
      if (isZoomed && lerpFactor <= 0.4) {
        setLerpFactor((prev) => Math.min(prev + delta * 0.2, 0.4)); 
        const result = camera.position.clone().add(meshRef.position);      
        const targetPosition = new THREE.Vector3().lerpVectors(camera.position, result.multiplyScalar(0.2), lerpFactor);    
        camera.position.lerp(targetPosition, 0.5);  

        const localForward = new THREE.Vector3(0, 0, -40);
        const worldForward = localForward.applyMatrix4(camera.matrixWorld);
        const currentLookAt = worldForward.sub(camera.position);
  
        const target = meshRef.position;
        const lerpStepX = delta * 0.2; // Adjust this value to control the speed of the transition
        let lerpFactorX = lerpFactor
        lerpFactorX = Math.min(lerpFactorX + lerpStepX, 0.1);
        currentLookAt.lerp(target, lerpFactorX);
  
        const newLookAtPosition = camera.position.clone().add(currentLookAt);
        camera.lookAt(newLookAtPosition);

      } else if (!isZoomed && lerpFactor < 0.4) {
        setLerpFactor((prev) => Math.min(prev + delta * 0.2, 0.4));         
        const result = camera.position.clone().add(origin);      
        const targetPosition = new THREE.Vector3().lerpVectors(camera.position, result.multiplyScalar(0.5), lerpFactor);    
        camera.position.lerp(targetPosition, 1);     

        const localForward = returnOrigin;
        const worldForward = localForward.applyMatrix4(camera.matrixWorld);
        const currentLookAt = worldForward.sub(camera.position);        
        currentLookAt.lerp(targetLookAt, lerpFactor);
        const newLookAtPosition = camera.position.clone().add(currentLookAt);
        camera.lookAt(newLookAtPosition);

      } else if (!isZoomed && lerpFactor >= 0.4 && lerpFactor < 1) { 
        setControlsEnabled(true);
      }
    }
  });

  const barModel = useRef()
  const { actions, mixer } = useAnimations(gltf.animations, barModel);
  useEffect(() => {
      actions['Dance.003'].setEffectiveTimeScale(0.95)
      actions['Dance.003'].play()
      actions['Armature|mixamo.com|Layer0.004'].play()
      actions['Armature.002|mixamo.com|Layer0.003'].play()
      actions['Armature.003|mixamo.com|Layer0.003'].play()
    }, [mixer])

  return <>
      <group onClick={handleClick}>
          <primitive
              ref={barModel}
              object={gltf.scene}
              position={[-5, -1.5, -3]}
              rotation = {[0, 0,0]}
          /> 
        </group>
  </>
}

export default Bar;