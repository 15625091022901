import React from 'react';
import './profile.css';


const Profile = ({ meshName }) => {

    return (
        <div className="profile-container">
            <div className="profile-content">
                <h1>Welcome Hogchop</h1>
                <img src='./hogchop-profile.jpeg' />
            </div>
      </div>     
     
    );
  };

  export default Profile
  