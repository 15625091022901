import React from 'react';
import './modal.css';


const Modal = ({ meshName, setModalVisible }) => {

    const handleClick = (event) => {
        setModalVisible((prevState) => !prevState);
    }
    return (
        <div className="modal-container" onClick={handleClick}>
            <div className="modal-content">
                <h2>Light Bloody Mary</h2>
                <img src='./Bloodymondays_250mL_Light_ProductShot_1080xcopy.png' />
                <div class="product__description rte">
                <p class="p1">What’s a Light Bloody Mary… Good question.</p>
                <p class="p1">Lighter than a traditional Bloody Mary but with the same punch and depth of flavour. We’ve used vodka, natural spices, real tomato juice, chilli and other ingredients you’d expect in a Bloody Mary.</p>
                <p class="p1">It’s lighter, meaning more drinkable, more of a grown up session cocktail that might even be one of your 5 a day!</p>
                <p class="p1">And it’s 6% because we want people to remember drinking it.</p>
                <p class="p1">Our products are for a more savoury palate. We’d recommend you try it – you’re never too old to experiment – try new things, imagine the possibilities.</p>
                <p class="p1">Sold as a four pack - 250 ml cans.</p>
                <p class="p1">Not sold as an individual can.</p>
                <p class="p1">&nbsp;</p>
                <p class="p1">*** FREE SHIPPING FOR ORDERS OVER $50 ***</p>
                </div>
            </div>
      </div>     
     
    );
  };

  export default Modal
  