import { Canvas, useThree } from '@react-three/fiber'
import { VRButton, ARButton, XR, Controllers, Hands, TeleportationPlane } from '@react-three/xr'
import ReactDOM from 'react-dom/client'
import * as THREE from 'three'
import React, { Suspense, useRef, useState, useEffect } from "react"

import Bar from './components/bar.js'
import LoadingScreen from './components/loadingScreen.js'
import Skybox from './components/skybox.js'
import AmbientAudio from './components/ambientAudio.js'
import Profile from './components/profile.js'
import Modal from './components/modal.js'
import MovementController from './components/MovementController.js'

import { OrbitControls, Sparkles,Sky, Stars, Cloud, useProgress } from '@react-three/drei'
import './style.css'

const App = () => {
    const [isModalVisible, setModalVisible] = useState(false);
    const [meshName, setMeshName] = useState('Initial text');

    const [isZoomed, setIsZoomed] = useState(false);
    const [lerpFactor, setLerpFactor] = useState(0);
    const [controlsTarget, setControlsTarget] = useState(new THREE.Vector3());
    const [controlsEnabled, setControlsEnabled] = useState(true);


  
    return (
      <>
        <Suspense fallback={<LoadingScreen />}>
        <VRButton />
          <Canvas
            gl={{
              antialias: true,
              toneMapping: THREE.ACESFilmicToneMapping,
              outputEncoding: THREE.sRGBEncoding,
            }}
            camera={{
              fov: 70,
              near: 0.01,
              far: 200,
              position: [0, 0.3, -15.5]
            }}
          >
           <XR camera={{
              fov: 70,
              near: 0.01,
              far: 200,
              position: [0, 0.3, -15.5]
            }}>
            <Controllers   />
            <MovementController
            hand="left"
            applyRotation={true}
            applyForward={true}
            />
            <MovementController
              hand="right"
              applyRotation={true}
              applyForward={true}
            />
            <TeleportationPlane leftHand rightHand visible="false" />
            <ambientLight intensity={0.3} color="#ffffff" />
            <directionalLight intensity={0.3} color="#ffffff" />
            <OrbitControls
            />
            
            <AmbientAudio />
            <Bar setModalVisible={setModalVisible} 
              setMeshName={setMeshName} 
              setIsZoomed={setIsZoomed} 
              isZoomed={isZoomed} 
              setLerpFactor={setLerpFactor} 
              lerpFactor={lerpFactor}
              controlsTarget={controlsTarget}
              setControlsTarget={setControlsTarget} 
              setControlsEnabled={setControlsEnabled}
            ></Bar>
             <Skybox />
             </XR>
          </Canvas>
          <Profile/>
          {isModalVisible && <Modal meshName={meshName} setModalVisible={setModalVisible}/>}
        </Suspense>
      </>
    );
  };

const root = ReactDOM.createRoot(document.querySelector('#root'));
root.render(<App />);