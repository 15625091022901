import { useProgress } from '@react-three/drei'

export default function LoadingScreen() {
    const {progress} = useProgress();
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "blue",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1,
        }}
      >
        <h1 style={{ color: "white" }}>{progress.toFixed(2)}%</h1>
      </div>
    );
  };
  