import { Canvas } from '@react-three/fiber';
import ReactDOM from 'react-dom/client';
import React, { Suspense, useRef, useState, useEffect } from "react";
import * as THREE from 'three';


export default function AmbientAudio() {
    const [audio] = useState(new Audio('../bmb-ambient-sound.mp3'))

    useEffect(() => {
        const playAudio = () => {
          audio.volume = 0.2
          audio.play()
          document.removeEventListener('click', playAudio)
        }
        document.addEventListener('click', playAudio)
        return () => {
          audio.pause()
          audio.currentTime = 0
          document.removeEventListener('click', playAudio)
        }
      }, [audio])
  }