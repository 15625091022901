import React, { Suspense, useRef, useState, useEffect } from "react"
import { Canvas, useThree, useLoader } from '@react-three/fiber'

import * as THREE from 'three'


export default function Skybox() {

        const texture = useLoader(THREE.TextureLoader, './bmb-skybox.png');
    texture.mapping = THREE.EquirectangularReflectionMapping
    texture.encoding = THREE.sRGBEncoding    
    const { scene } = useThree();

    scene.environment = texture
    scene.background = texture

  };
  